import React from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import {

  useInView,

} from 'framer-motion'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { t } from "i18next";
import { Link } from 'gatsby';
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import QuickBooks from '../images/logos/quickbooks.svg';
import Xero from '../images/logos/xero-icon.svg';
import Capterra from '../images/logos/capterra-icon.svg';
import Shopify from '../images/logos/shopify.svg';

import Charlie from '../images/home/review-pics/charlie.png'
import Garret from '../images/home/review-pics/garret.png'
import Ken from '../images/home/review-pics/ken.jpeg'
import Jeff from '../images/home/review-pics/jeff.jpeg'
import Dan from '../images/home/review-pics/dan.jpeg'
import Lorretta from '../images/home/review-pics/lorretta.jpeg'
import Mariko from '../images/home/review-pics/mariko.jpeg'
const reviews = [
  {
    body: '"I am a big believer in automating as many things as possible in my life. taxomate allows me to automatically import my sales and COGS into Quickbooks. This comes out to being a fraction of the cost of using an amazon bookkeeper."',
    type: 'Seller',
    author: 'Charlie Holiday',
    sales: '$10MM Annual Sales',
    rating: 5,
    image: Charlie,
    location: "QuickBooks",
  },
  {
    body: '"<b>We love Taxomate</b>, as a new seller, has been much easier to get started for us than A2X. A2X was very confusing and difficult. taxomate setup was a breeze and support has been great! We highly recommend for anyone looking for an affordable QuickBooks integration for Amazon."',
    type: 'Seller',
    author: 'Garret Smith',
    sales: '$1MM Annual Sales',
    rating: 5,
    location: "QuickBooks",
    image: Garret,
  },
  {
    body: '"<b>My VAT returns have become a lot simpler</b> since I started using this product. Their support team is always very helpful and informative. I would highly recommend this product."',
    type: 'Seller',
    author: 'Dan Preston',
    sales: '$4MM Annual Sales',
    rating: 5,
    location: "QuickBooks",
    image: Dan,
  },
  {
    body: '"<b>Switched from A2X due to the cost</b> and so happy I did. Easier to use and cheaper! What is there to complain about!"',
    type: 'Seller',
    author: 'Mariko P.',
    sales: '$10MM Annual Sales',
    rating: 5,
    location: "Xero",
    image: Mariko,
    //fix
  },
  {
    body: '"I am a big believer in automating as many things as possible in my life. taxomate allows me to automatically import my sales and COGS into Quickbooks. This comes out to being a <b>fraction of the cost of using an amazon bookkeeper.</b>"',
    type: 'Seller',
    author: 'Charlie Holiday',
    sales: '$5MM Annual Sales',
    rating: 5,
    location: "QuickBooks",
    image: Charlie,
  },
  {
    body: `"I needed a service that would import all my transaction from Amazon to QuickBooks Online (including past transactions), and account for returns, reimbursements, Amazon fees, storage fees, etc. <b>Taxomate is the most simple to use app.</b>` + `<b>I found and their customer service is EXCELLENT!</b>` + `It saved me hours and hours of trying to match my Amazon transactions to my Bank Transactions and now I have one Profit & Loss statement (with transactions imported from Amazon) super well categorized all in one place on QuickBooks. I highly recommend."`,
    type: 'Seller',
    author: 'Jeff Brookes',
    sales: '$3MM Annual Sales',
    rating: 5,
    location: "QuickBooks",
    image: Jeff,
  },
  {
    body: '"Generally dont write reviews but had such a great experience with taxo. Really simple to use and eventually got it <b>matching with my 1099</b>. Much better than a2x and cheaper."',
    type: 'Seller',
    author: 'Lorretta Kautz',
    sales: '$2MM Annual Sales',
    rating: 5,
    location: "Xero",
    image: Lorretta,


    //fix
  },
  {
    body: '"<b>Made my life so much easier.</b> I am now able to easily compare to my 1099s and file my tax returns. I was previously manually entering into QuickBooks and this was usually a nightmare. Important to note that depending upon your business this may take some time to set up, so make sure to set aside some time in the beginning. Once you do it is pretty much automated from there. Thanks again for all the support."',
    type: 'Seller',
    author: 'Ken Hughes',
    sales: '$2MM Annual Sales',
    rating: 5,
    location: "QuickBooks",
    image: Ken,
  },
]

function StarIcon(props) {
  return (
    <svg viewBox="0 0 20 20" aria-hidden="true" {...props}>
      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
    </svg>
  )
}

function StarRating({ rating }) {
  return (
    <div className="flex">
      {[...Array(5).keys()].map((index) => (
        <StarIcon
          key={index}
          className={clsx(
            'h-5 w-5',
            rating > index ? 'fill-yellow-400' : 'fill-gray-300'
          )}
        />
      ))}
    </div>
  )
}

function Review({ title, body, author, rating, image, className, location, sales, ...props }) {
  let animationDelay = useMemo(() => {
    let possibleAnimationDelays = ['0s', '0.1s', '0.2s', '0.3s', '0.4s', '0.5s']
    return possibleAnimationDelays[
      Math.floor(Math.random() * possibleAnimationDelays.length)
    ]
  }, [])

  return (
    <figure
      className={clsx(
        'animate-fade-in rounded-3xl bg-white p-6 opacity-0 shadow-md shadow-gray-900/5',
        className
      )}
      style={{ animationDelay }}
      {...props}
    >
      <div className='py-6'>
      <blockquote className="text-gray-900">
        <div className='inline'>
        <StarRating rating={rating} />

          </div>
          <p className="mt-3 text-sm text-gray-600 font-heading"><div dangerouslySetInnerHTML={{ __html: body }} /></p>


      </blockquote>
      {/* <figcaption className="mt-3 text-sm text-gray-600 before:content-['–_']">
        {author}
      </figcaption> */}


        <div className='flex flex-wrap'>
          <div className='w-1/8 pr-3'>
          <img
            className="mx-auto w-10 h-10 rounded-full"
            src={image}
            alt="ecommerce integration service"
            />
          </div>
          <div className='w-7/8'>
          <p className="text-sm text-center text-gray-500 py-2">{author} │ <span className="text-green-700">{sales}</span> </p>
          </div>
        </div>
      <div className="inline-flex items-center text-xs float-right font-bold text-blue-600">

        <CheckBadgeIcon className="h-9 w-9 px-2" />
        {location === "QuickBooks" ? `Verified — QuickBooks App Store` : ``}
        {location === "Xero" ? `Verified — Xero App Store` : ``}

        </div>
      </div>
    </figure>
  )
}

function splitArray(array, numParts) {
  let result = []
  for (let i = 0; i < array.length; i++) {
    let index = i % numParts
    if (!result[index]) {
      result[index] = []
    }
    result[index].push(array[i])
  }
  return result
}

function ReviewColumn({
  className,
  reviews,
  reviewClassName = () => {},
  msPerPixel = 0,
}) {
  let columnRef = useRef()
  let [columnHeight, setColumnHeight] = useState(0)
  let duration = `${columnHeight * msPerPixel}ms`

  useEffect(() => {
    let resizeObserver = new window.ResizeObserver(() => {
      setColumnHeight(columnRef.current.offsetHeight)
    })

    resizeObserver.observe(columnRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  return (
    <div
      ref={columnRef}
      className={clsx('animate-marquee space-y-8 py-4', className)}
      style={{ '--marquee-duration': duration }}
    >
      {reviews.concat(reviews).map((review, reviewIndex) => (
        <Review
          key={reviewIndex}
          aria-hidden={reviewIndex >= reviews.length}
          className={reviewClassName(reviewIndex % reviews.length)}
          {...review}
        />
      ))}
    </div>
  )
}


 function Container({ className, ...props }) {
  return (
    <div
      className={clsx('mx-auto max-w-7xl px-4 sm:px-6 lg:px-8', className)}
      {...props}
    />
  )
 }

  function ReviewGrid() {
  let containerRef = useRef()
  let isInView = useInView(containerRef, { once: true, amount: 0.4 })
  let columns = splitArray(reviews, 3)
  columns = [columns[0], columns[1], splitArray(columns[2], 2)]

    return (


    <div
      ref={containerRef}
      className="relative -mx-4 grid h-[49rem] max-h-[150vh] grid-cols-1 items-start gap-8 overflow-hidden px-4 md:grid-cols-2"
    >
      {isInView && (
        <>
          <ReviewColumn
            reviews={[...columns[0], ...columns[2].flat(), ...columns[1]]}
            reviewClassName={(reviewIndex) =>
              clsx(
                reviewIndex >= columns[0].length + columns[2][0].length &&
                  'md:hidden',
                reviewIndex >= columns[0].length && 'lg:hidden'
              )
            }
            msPerPixel={30}
          />
          <ReviewColumn
            reviews={[...columns[1], ...columns[2][1]]}
            className="hidden md:block"
            reviewClassName={(reviewIndex) =>
              reviewIndex >= columns[1].length && 'lg:hidden'
            }
            msPerPixel={35}
          />
          {/* <ReviewColumn
            reviews={columns[2].flat()}
            className="hidden lg:block"
            msPerPixel={10}
          /> */}
        </>
      )}
      {/* <div className="pointer-events-none absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-50" />
      <div className="pointer-events-none absolute inset-x-0 bottom-0 h-32 bg-gradient-to-t from-gray-50" /> */}
    </div>
  )
}

export default function Reviews() {

  return (
    <section
      id="reviews"
      aria-labelledby="reviews-title"
      className="pt-20 pb-16 sm:pt-32 sm:pb-24"
    >

      <Container className="lg:grid lg:grid-cols-3 ">
        <div>
          <h3 className="text-4xl font-bold text-gray-700">Don't just take our word for it.</h3>
          <p className='text-xl text-gray-500 py-5'>Find out why our users trust taxomate for their ecommerce bookkeeping.</p>
          <div className="">
            <Link to="https://quickbooks.intuit.com/app/apps/appdetails/amazonintegration/en-us/">
            <div className="inline-flex items-center divide-x divide-gray-300 py-5">
              <img className="h-5" src={QuickBooks} alt="top amazon integration reviews on quickbooks" />
              <div>
                  <div className="shrink-0 flex pr-5 justify-center">
                    <StarIcon className="h-5 w-5 fill-green-700" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 fill-green-700" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 fill-green-700" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 fill-green-700" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 fill-green-700" aria-hidden="true" />
                  </div>
                  <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                  <span className="font-medium text-grey-700"><Trans>Rated 5.0 • 170+ Reviews</Trans></span>
                  </div>
              </div>
            </div>
            </Link>
            <Link to="https://apps.xero.com/us/app/taxomate">

            <div className="inline-flex items-center divide-x divide-gray-300 py-5">
              <img className="h-16" src={Xero} alt="top amazon integration reviews on xero" />
              <div>
                <div className="shrink-0 flex pr-5 justify-center">

                  <StarIcon className="h-5 w-5 fill-blue-400" aria-hidden="true" />
                  <StarIcon className="h-5 w-5 fill-blue-400" aria-hidden="true" />
                  <StarIcon className="h-5 w-5 fill-blue-400" aria-hidden="true" />
                  <StarIcon className="h-5 w-5 fill-blue-400" aria-hidden="true" />
                  <StarIcon className="h-5 w-5 fill-blue-400" aria-hidden="true" />
                </div>
                <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                  <span className="font-medium text-grey-700"><Trans>Rated 5.0 • 40+ Reviews</Trans></span>
                </div>
              </div>
              </div>
            </Link>
            <Link to="https://apps.shopify.com/xero-taxomate">

              <div className="inline-flex items-center divide-x divide-gray-300 py-5">
                <img className="h-11 px-3" src={Shopify} alt="top amazon integration reviews on shopify" />
                <div>
                  <div className="shrink-0 flex pr-5 justify-center">

                    <StarIcon className="h-5 w-5 fill-lime-600" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 fill-lime-600" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 fill-lime-600" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 fill-lime-600" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 fill-lime-600" aria-hidden="true" />
                  </div>
                  <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                    <span className="font-medium text-grey-700"><Trans>Rated 4.9 • 14+ Reviews</Trans></span>
                  </div>
                </div>
              </div>
            </Link>
           <Link to="https://www.capterra.com/p/187763/taxomate/">
            <div className="inline-flex items-center divide-x divide-gray-300 py-5">
              <img className="h-10 px-3" src={Capterra} alt="top amazon integration reviews on capterra" />
              <div>
                <div className="shrink-0 flex pr-5 justify-center">
                  <StarIcon className="h-5 w-5 fill-yellow-400" aria-hidden="true" />
                  <StarIcon className="h-5 w-5 fill-yellow-400" aria-hidden="true" />
                  <StarIcon className="h-5 w-5 fill-yellow-400" aria-hidden="true" />
                  <StarIcon className="h-5 w-5 fill-yellow-400" aria-hidden="true" />
                  <StarIcon className="h-5 w-5 fill-yellow-400" aria-hidden="true" />
                </div>
                <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                  <span className="font-medium text-grey-700"><Trans>Rated 5.0 • 14+ Reviews</Trans></span>
                </div>
              </div>
              </div>
            </Link>

            <div className='py-10 invisible lg:visible'>
                <Link
                  to="/reviews"
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-2 border-blue-800 rounded-md shadow text-base font-medium text-white bg-blue-600 hover:bg-white hover:text-blue-600"
                >
                  <Trans>See More Reviews</Trans>
                  <div className="px-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>
                  </div>
                  </Link>
            </div>
          </div>
        </div>
        <div className='lg:col-span-2'>
          <ReviewGrid />
        </div>
      </Container>
    </section>
  )
}
