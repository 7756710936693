import React, { useState } from "react";
import Layout from "../components/layout";
import { StarIcon, CheckBadgeIcon } from "@heroicons/react/20/solid";
import { ArrowsRightLeftIcon, BoltIcon, ScaleIcon, CheckCircleIcon} from '@heroicons/react/24/outline'
import { StaticImage } from "gatsby-plugin-image";
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle'
import { graphql, Link } from 'gatsby';
import Lottie from "../components/lottie-home"
import SEO from "../components/seo/seo-new"
import Reviews from "../components/reviews"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { t } from "i18next";
import { Transition } from '@headlessui/react'
import Quicksetup from "../images/home/quick-setup.svg"
import Profit from "../images/home/profit&loss.svg"
import Match from "../images/home/matched.svg"
import Netgrowth from "../images/home/netgrowth.svg"



import Lutz from '../images/home/reviews/lutz.jpeg'
import Lorretta from '../images/home/review-pics/lorretta.jpeg'
import Robert from '../images/home/reviews/robert.jpeg'
import Sophie from '../images/home/reviews/sophie.jpeg'

export const homeQuery = graphql`
query MyQueryHome ($language: String!) {

  allPrismicTestimonial (filter: {data: {home: {eq: true}}}) {
    nodes {
      data {
        testimonial_text {
          html
        }
        seller_sales_amount
        review_location
        name
        home
        avatar {
          url
        }
      }

    }
  }
  allPrismicEcommerce (sort: { fields: data___order, order: ASC }){
    edges {
      node {
        data {
          logo_white_svg {
            url
          }
          logo_white_height
          logo {
            url
          }
          color
          ecommerce_name {
            text
          }
          order
          icon {
            url
          }
          logo_white_width {
            text
          }
          logo_white_class
        }
        uid
      }
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  allLocale {
    edges {
      node {
        ns
        data
        language
      }
    }
  }


}
`



export default function IndexPage({ data }) {
  const { t } = useTranslation();


  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const [isShowing1, setIsShowing1 ] = useState(false)

  const [isShowing2, setIsShowing2 ] = useState(false)
  const [isShowing3,  setIsShowing3 ] = useState(false)
  const [isShowing4, setIsShowing4] = useState(false)

  const features = [
    {
      name: t('Manage Your Business Like a Pro'),
      description: t('Taxomate helps you manage your finances like a pro and optimize your business. With our guided setup process, you can connect your sales channels to your accounting software in minutes.'),
      reviewText: t('Accounting for Amazon transactions is complicated, but taxomate makes it easy. I have been looking for a solution to automate this and taxomate is perfect.'),
      reviewName: t('Matt Lutz'),
      reviewType: t('Amazon Seller'),
      color: `bg-orange-50`,
      reviewColor: `bg-orange-200`,
      icon: BoltIcon,
      reviewImage: Lutz,
      image: Quicksetup,
      isShowing: isShowing1,
      setIsShowing: setIsShowing1,
    },
    {
      name: t('Save Time and Money'),
      description: t('In business, time is money – and when you use Taxomate to automate your eCommerce bookkeeping and accounting, you’re saving both. Taxomate compiles your transactions into one invoice for easy matching with bank deposits from your marketplace.'),
      reviewText: t('As a small business, I used to pay my accountant a lot of money to process Amazon fees. Taxomate is less expensive than paying my accountant to do it. The setup was easy, and I have left it running for a few months now.'),
      reviewName: t('Sophie Harper'),
      reviewType: t('Amazon Seller'),
      color: `bg-emerald-50`,
      reviewColor: `bg-emerald-200`,
      image: Match,
      icon: BoltIcon,
      isShowing: isShowing2,
      setIsShowing: setIsShowing2,
      reviewImage: Sophie,
    },
    {
      name: t('Maximize Your Profitability'),
      description: t('By understanding your sales, fees, advertising costs, shipping costs, COGS, and more, you can make informed decisions that can help your business thrive and your bottom-line skyrocket.'),
      reviewText: t('Without much work I am able to get a full view of my profitibility.'),
      reviewName: t('Robert Mullen'),
      reviewType: t('Amazon Seller'),
      color: `bg-red-50`,
      reviewColor: `bg-red-200`,
      image: Netgrowth,
      icon: BoltIcon,
      isShowing: isShowing3,
      setIsShowing: setIsShowing3,
      reviewImage: Robert,
    },
    {
      name: t('Minimize Mistakes For Tax Time'),
      description: t('Taxomate makes it easier than ever to understand your financials and make accurate quarterly or year-end tax filings. Make tax season a breeze!'),
      reviewText: t('Generally dont write reviews but had such a great experience with taxo[mate]. Really simple to use and eventually got it matching with my 1099. Much better than a2x and cheaper.'),
      reviewName: t('Lorretta Kautz'),
      reviewType: t('Ecommerce Seller'),
      color: `bg-blue-50`,
      reviewColor: `bg-blue-200`,
      image: Profit,
      icon: BoltIcon,
      isShowing: isShowing4,
      setIsShowing: setIsShowing4,
      reviewImage: Lorretta,
    },

  ]




  const lang = data.locales.edges[0].node.language
  // const altLang2 = data.locales.edges[0].next.language
  // const altLang3 = data.locales.edges[0].previous.language

  const ecoms = data.allPrismicEcommerce.edges

  const testimonials = data.allPrismicTestimonial.nodes

  let url;

  const originalPathname = '/'

  lang == "en" ?
    url = `/`
    :
    url = `/${lang}/`

  // altLang2 === null ?
  //   url2 = null
  //   :
  //   url2 = `/${altLang2}/`

  // altLang3 === null ?
  //   url3 = null
  //   :
  //   url3 = `/${altLang3}/`

  const title = t('Amazon, Shopify, eBay Accounting Integration Software')

  const description = t('Amazon, Shopify, eBay, Walmart accounting integration made easy. Automated accounting and bookkeeping for ecommerce sellers.')




  return (
    <Layout title="Amazon, Shopify, eBay Accounting Integration Software">
      <SEO
        title={title}
        description={description}
        image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
        pathname={url}
        originalPathname={originalPathname}
        lang={lang}
        alternate

      />

      {/* <GatsbySeo
        title="Amazon, Shopify, eBay Accounting Integration Software | taxomate"
        description="Amazon, Shopify, eBay, Walmart accounting integration made easy. Automated accounting and bookkeeping for ecommerce sellers."
        canonical="https://taxomate.com"
        openGraph={{
          url: "https://taxomate.com",
          title: "Amazon, Shopify, eBay Accounting Integration Software | taxomate",
          description: "Amazon, Shopify, eBay, Walmart accounting integration made easy. Automated accounting and bookkeeping for ecommerce sellers.",
          images: [
            {
              url: "https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg",
              width: 1200,
              height: 628,
              alt: "ecommerce seller accounting integration made easy. Automated accounting and bookkeeping for ecommerce sellers.",
            },
          ],
        }}
        twitter={{
          handle: '@gettaxomate',
          site: '@taxomate',
          cardType: 'summary_large_image',
        }}
        languageAlternates={[
          {
            hrefLang: 'fr',
            href: `https://fr.taxomate.com`,
          },
          {
            hrefLang: 'es',
            href: `https://es.taxomate.com`,
          }
        ]}
      /> */}




      <section className="pt-5 py-10 bg-white">
        <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-20 pb-20">
          <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
            <div>



              <div className="mt-1">

                <div className="sm:max-w-xl">
                  <h1 className="text-3xl font-bold text-gray-600 sm:text-4xl ">
                    <Trans>Automate Your </Trans>
                    <span className="bg-blue-600 text-white px-1"><Trans>Ecommerce </Trans></span>
                    <span className="bg-blue-600 text-white px-1"><Trans> Accounting</Trans></span>

                  </h1>



                    <ul class="list-none pt-5">
                      <li class="flex items-center mb-2">
                          <div className="h-8 w-8">
                            <CheckCircleIcon className="h-8 w-8 fill-lime-50 text-green-400 drop-shadow-[0_5px_5px_rgba(217,249,157,1)] pr-3"></CheckCircleIcon>
                          </div>
                          <span class="text-sm">Easily connect your sales channels with your accounting software.</span>
                      </li>
                      <li class="flex items-center mb-2">
                          <div className="h-8 w-8">
                            <CheckCircleIcon className="h-8 w-8 fill-lime-50 text-green-400 drop-shadow-[0_5px_5px_rgba(217,249,157,1)] pr-3"></CheckCircleIcon>
                          </div>
                          <span class="text-sm">Reduce overhead and minimize mistakes for tax time.</span>
                        </li>
                      <li class="flex items-center mb-2">
                        <div className="h-8 w-8">
                          <CheckCircleIcon className="h-8 w-8 fill-lime-50 text-green-400 drop-shadow-[0_5px_5px_rgba(217,249,157,1)] pr-3"></CheckCircleIcon>
                          </div>
                      <span class="text-sm">Understand your financials to grow your business.  </span>
                        </li>
                  </ul>

                </div>



                <div className="mt-6">


                  <a
                    href="https://app.taxomate.com/sign-up"
                    className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border-2 border-blue-800 rounded-md shadow  text-base font-medium text-white bg-blue-600 hover:bg-blue-700"
                  >
                    <Trans>Try For Free</Trans>

                  </a>
                </div>
                <div className="pt-3 pl-1">
                  <span className="text-[11px]">
                    Start Your 14 Day Free Trial
                  </span>
                </div>

                <div className="mt-5">
                  <div className="inline-flex items-center divide-x divide-gray-300">
                    <div className="shrink-0 flex pr-5">
                      <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                      <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>



                    <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-500 sm:py-3">
                      <span className="font-medium text-gray-900"><Trans>Rated 5 stars</Trans></span> by{' '}
                      <Link to="/reviews">
                        <span className="font-medium text-blue-600"><Trans>hundreds of ecommerce Sellers</Trans></span>
                      </Link>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>

          <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
            <div className="sm:relative sm:py-3 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">

              <div className="hidden md:block relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 h-full">
                <Lottie></Lottie>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="flex justify-center items-center h-full pb-20 pl-0 px-0 transition-none">



        <div className="flex flex-wrap justify-center gap-5 px-2 md:px-0">

          {ecoms.map((ecom) => (

            <Link className={`transform hover:scale-105 inline rounded-lg pt-0 pb-10 py-8 shadow border-2 border-blue-800 w-full md:w-custom gap-3 bg-${ecom.node.data.color}`} to={`/${ecom.node.uid}`}>
              <div className="flex justify-center align-items-center">

                <img className={`${ecom.node.data.logo_white_class}`} width={`${ecom.node.data.logo_white_width.text}`} src={`${ecom.node.data.logo_white_svg.url}`} alt={`Connect ${ecom.node.data.ecommerce_name.text} to Your Accounting Software`} />

              </div>
              <div className="flex md:absolute md:bottom-3 md:left-0 md:right-0 justify-center text-white items-center mt-0 text-lg text-center">
                <Trans>
                  Learn More

                </Trans>


                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 pl-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                    </svg>



              </div>
            </Link>
          ))
          }

        </div>

      </section>




     <section class="py-10 pb-20 bg-gray-50">
        <div class="container px-4 mx-auto relative">
          <h2 class="text-3xl md:text-4xl text-center font-bold md:px-40 py-5 text-gray-600">How can Taxomate help?</h2>

          <div class="flex flex-wrap -mx-4 -mb-16">
            {features.map((feature) => (

            <div class="w-full md:w-1/2 px-1 py-6">
                <div className={`h-64 py-6 px-3 ${feature.color} shadow border-2 border-blue-800 rounded-xl`} onMouseEnter={() => feature.setIsShowing(true)} onMouseLeave={() => feature.setIsShowing(false)}>
                  <div className="flex flex-wrap">
                    <div className="w-3/5 px-2">
                  <div className="inline-block">
                    <h4 class="text-xl font-bold mb-4">{feature.name}</h4>
                  </div>
                  <p class="text-sm  mb-8">
                    {feature.description}
                    </p>
                    </div>
                    <div className="w-2/5 pl-2 my-auto">
                      <img src={feature.image} className="max-h-[13rem]" alt={feature.name} />
                    </div>
                </div>
              </div>

                <Transition
                  appear={true}
                  show={feature.isShowing}
                  enter="transition-opacity duration-75"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity duration-150"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >

              <div class={`${feature.reviewColor} w-11/12 float-right	-mt-10 shadow border-2 border-blue-800 rounded-lg overflow-hidden`}>
                <div class="w-full bg-blue bg-opacity-50 rounded-5xl">

                  <div className="py-3 px-3">
                    <svg aria-hidden="true" class="w-4 h-4 text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z" fill="#1e40af" /></svg>
                      <p class="mb-2 text-sm">{feature.reviewText}</p>
                    <div class="flex flex-wrap -m-1.5 py-3 px-3">
                          <div className='flex flex-wrap'>
                            <div className='w-1/8 pr-3'>
                              <img
                                className="mx-auto w-10 h-10 rounded-full"
                                src={feature.reviewImage}
                                alt="ecommerce integration service"
                              />
                            </div>
                      <div class="w-7/8 p-1.5">
                          <h3 class="font-heading text-gray-900 font-semibold text-xs">{feature.reviewName}</h3>
                          <p class="text-xs text-gray-600">{feature.reviewType}</p>
                            </div>
                            </div>
                    </div>
                  </div>
                </div>
                  </div>


              </Transition>

              </div>

            ))}




          </div>
        </div>
      </section>









      <Reviews testimonials={testimonials} />


      <section className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="bg-gradient-to-r from-feeling_moody_start to-feeling_moody_end rounded-3xl overflow-hidden shadow border-2 border-blue-800">


      <div className="px-6 py-24 sm:px-6 sm:py-20 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-600 sm:text-4xl">
            Business can be tough.        <br />
                  But your accounting doesn’t have to be!
                </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
                  <Trans>  Simplify your ecommerce bookkeeping with Taxomate today!</Trans>
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
                    href="https://app.taxomate.com/sign-up"
              className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow border-2 border-blue-800 hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    Try for Free
                  </a>


                </div>
                <div className="pt-3 pl-1">
                  <span className="text-[11px]">
                    Start Your 14 Day Free Trial
                  </span>
                </div>
        </div>
      </div>
          </div>
        </div>
      </section>



    </Layout >
  );
}