import React, { createRef, useEffect } from 'react'

// import lottie
import lottie from 'lottie-web';


// For when animationContainer is on screen
import OnScreen from 'onscreen';

// import animation data
import animationData from '../images/animation/data.json';

const YourAwesomeAnimation = (props) => {
    let lottieAnimationName = createRef();
    let anim = null;

    useEffect(() => {
        anim = lottie.loadAnimation({
            container: lottieAnimationName.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                progressiveLoad: true,
            }
        });

        // // Go to the first frame and pause
        // anim.goToAndStop(0);

        // const os = new OnScreen();

        // os.on('enter', '.animation-container', (element, event) => {
        //     anim.playSegments(
        //         [
        //             [0, 30],
        //             [30, 300]
        //         ],
        //         true
        //     );// Play parts and loop end of animation
        //     anim.setSpeed(0.8); // Set the speed of the animation
        //     // anim.play(); Use if not playing segments
        // });

        // // os.on('leave', '.animation-container', (element, event) => {
        // //   anim.goToAndStop(0);
        // // });

        return () => {
            // anim.destroy();

        } // optional clean up for unmounting
    }, []);

    return (
        <div style={{ maxWidth: '700px', width: '100%' }} className="animation-container" ref={lottieAnimationName} />
    )
}
export default YourAwesomeAnimation;